@import 'styles/text.scss';
@import 'styles/boxes.scss';

.layout {
  .badge {
    position: absolute;
    top: 0.75rem;
    left: -3rem;
    z-index: 1;

    svg {
      position: absolute;
    }

    .label {
      color: RGB(var(--theme_text_light));
      min-height: 1rem;
    }
  }
}
