@import '@ticknovate/frontend-shared/style/text.scss';

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.input {
  @include text_body;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  margin: 0;
  padding: 0;
  width: 100%;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;

  &::-webkit-input-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }

  &:-moz-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }

  &::-moz-placeholder {
    opacity: 1;
    color: RGB(var(--theme_text_placeholder));
  }

  &:-ms-input-placeholder {
    color: RGB(var(--theme_text_placeholder));
  }

  &:disabled {
    color: RGB(var(--theme_text_disabled));

    &::-webkit-input-placeholder {
      color: RGB(var(--theme_text_disabled));
    }

    &:-moz-placeholder {
      color: RGB(var(--theme_text_disabled));
    }

    &::-moz-placeholder {
      opacity: 1;
      color: RGB(var(--theme_text_disabled));
    }

    &:-ms-input-placeholder {
      color: RGB(var(--theme_text_disabled));
    }
  }
}
